@import "/src/app/styles/resizeValues.scss";
@import "/src/app/styles/index.scss";

.Wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5rem;
}

.Image {
  width: 100%;
  height: auto;
}

.Button {
  margin-top: 1.2rem;
}

@media (min-width: $min-mobile-width) {
  .Wrapper {
    grid-template-columns: 3fr 1fr;
    column-gap: 1rem;
  }

  .Title {
    line-height: 1em;
  }

  .Image {
    margin: 0;
  }
}
