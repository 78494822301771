@import "/src/pages/Page.module.scss";
@import "/src/app/styles/resizeValues.scss";

.Page {
  display: grid;
  grid-template-columns: 100%;
}

@media (min-width: $min-mobile-width) {
  .Page {
    grid-template-columns: 80% 20%;
  }
}
