@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";

.LinkText {
  text-decoration: none;
  color: $base-white;
}

.PinkImage {
  color: $base-pink;
  margin-right: 0.5rem;
}

.PlinkDisabled {
  pointer-events: none;
  color: $base-white;
  font-size: 13px;
}

.Plink {
  color: $base-white;
  font-size: 13px;

  &:hover {
    color: $base-pink;
  }
}

.PlinkDisabledBig {
  pointer-events: none;
  color: $base-white;
  text-decoration: none;
  font-weight: bold;
  line-height: 36px;
}

.Container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1.25rem;
}

.InnerContainer {
  display: flex;
  flex-direction: column;
}

.IconsContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.ButtonBottomLink {
  color: $base-white;
  font-size: 17px;
  text-decoration: none;
}

.Logo {
  display: none;
  justify-self: left;
}

.LogoAndLinksContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: $min-thin-desktop-width) {
  .Container {
    justify-content: space-between;
  }

  .Logo {
    display: block;
  }
}
