@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";
@import "/src/pages/Page.module.scss";

.Title {
  text-align: center;
}

.Content {
  width: 80%;
  margin: auto;
}

.ButtonLayer {
  position: sticky;
  float: right;
  bottom: 50px;
  margin-right: 10px;
}

.AlreadySolved {
  font-family: "Montserrat";
  margin: 0;
  font-weight: normal;
  text-align: left;
  font-size: 1rem;
}

.BackToTasksButton {
  position: sticky;
  float: right;
  bottom: 50px;
}

.TaskLayer {
  padding: 0.75rem;
  border: $black-border solid thin;
  font-weight: 500;
  font-size: 16px;
  color: $base-blue;
}

.centeredContent {
  padding-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Text {
  font-size: 16px !important;
  text-align: justify;
}

.TasksList {
  padding-top: 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Task {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}

.TaskTitle {
  font-size: 36px;
  text-align: left;
  overflow-wrap: break-word;
}

.TaskNumber {
  font-size: 36px;
  line-height: 37px;
  text-align: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 550;
}

.AnswerInput {
  padding: 10px;
  border: $black-border solid thin;
  font-weight: bolder;
  display: table;
  vertical-align: bottom;
  width: 90%;
  height: 75%;
}

.RegExpAnswer {
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  vertical-align: bottom;
  line-height: 18px;
}

.AnswerArea {
  font-weight: lighter;
  vertical-align: bottom;
  text-align: start;
  justify-content: end;
  white-space: pre-wrap;
}

.AnswerInput:after {
  color: $base-pink !important;
}

.TasksPage {
  padding-top: 15px;
}

.LinksLayer {
  padding-top: 10px;
  text-align: center;
  white-space: pre-line;
}

.MainTextLink {
  color: $base-blue;
}

.Hint {
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 16px !important;
}

.TaskCode {
  text-align: justify;
  font-size: 12px;
  opacity: 0.5;
}

.EditLink {
  text-decoration: none;
  color: #808080;
  font-size: 12px;

  &:hover {
    color: #808080 !important;
    font-size: 12px;
  }
}

@media(min-width: $min-mobile-width) {
  .TaskTitle {
    text-align: center;
  }
}
