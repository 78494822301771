@import "/src/app/styles/index.scss";

.Quote {
  background-color: $background;
  border-radius: $border-radius;

  padding-inline: 2rem;
  padding-block: 0.1rem;
  animation-name: show;
  animation-duration: 1s;
  color: $base-blue;

  box-shadow: $box-shadow $box-shadow $box-shadow rgba(0, 0, 0, 0.2);
}

.AuthorLink {
  margin-top: 5rem;
}

.Author {
  font-size: 1rem;
}
