@import "/src/app/styles/resizeValues.scss";

.Wrapper {
  --gap: 3rem;

  display: grid;
  margin-top: 5rem;
  margin-bottom: 5rem;
  gap: var(--gap);
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: $min-mobile-width) {
  .Wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: $min-thin-desktop-width) {
  .Wrapper {
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(33.33% - var(--gap)), 1fr)
    );
  }
}
