@import "../../pages/Page.module.scss";
@import "/src/app/styles/resizeValues.scss";
@import "/src/app/styles/index.scss";

.Title {
  font-size: 36px;
  line-height: 44px;
  display: flex;
  text-align: left;
  color: $base-white;
}

.Header {
  background-color: $base-blue;
  color: $base-white;
  min-height: 600px;
}

.Grid {
  display: grid;
}

.Text {
  display: flex;
}

.Text li {
  color: $base-white;
}

.Text a {
  color: $base-white;
}
.Text p {
  color: $base-white;
}

.Image {
  margin: auto;
  padding-bottom: 20px;
  max-width: 100%;

  max-height: 400px;
  min-height: 50px;
}

.Image.resize {
  height: auto;
}

.RotatedImage {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: $base-blue;
  transform: rotate(4.26deg);
  bottom: 20px;
  left: 60px;
}

@media (min-width: $min-mobile-width) {
  .Title {
    text-align: center;
    padding-top: 60px;
  }
}

@media (min-width: $min-thin-desktop-width) {
  .Image {
    margin: 0;
  }

  .Grid {
    grid-template-columns: 2fr 2fr;
    gap: 3rem;
  }
}
