@import "/src/app/styles/resizeValues.scss";
@import "/src/app/styles/index.scss";

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5rem;
}

.image {
  width: 100%;
  height: auto;
}

.button {
  margin-top: 1.2rem;
}

.title {
  text-transform: uppercase;
}

@media (min-width: $min-mobile-width) {
  .wrapper {
    grid-template-columns: 3fr 1fr;
    column-gap: 1rem;
  }

  .title {
    line-height: 1em;
  }

  .image {
    margin: 0;
  }
}
