@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";

.Name {
  background-color: $base-white;
  width: 100%;

  border: 1px solid $base-pink;
  border-radius: 14px;

  font-weight: 800;
  font-size: 20px;
  text-align: center;
  color: $base-pink;
  margin-right: 10px;
  text-decoration-line: none;
  white-space: pre-line;
  margin-top: 9px;
  text-transform: initial;
  padding: 7px;
  line-height: 25px;

  box-sizing: border-box;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));

  &:hover {
    opacity: 70%;
    cursor: pointer;
  }

  &:active {
    transform: translateY(4px);
  }
}

@media (min-width: $min-mobile-width) {
  .Name {
    width: auto;
  }
}
