@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";
@import "/src/pages/Page.module.scss";

.Wrapper {
  row-gap: 1rem;
}

.centeredContent {
  padding-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Exams {
  display: flex;
  flex-direction: column;
}

.ExamsCards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.ThemesWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.scrollButton {
  width: fit-content;
  margin: auto;
  top: 90%;
  left: 0;
  right: 0;
}

@media (min-width: $min-mobile-width) {
  .ThemesWrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
