@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";

.Card {
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-top: 25px;
  padding-left: 45px;

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: none;
}

.Info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 1.5rem;
  justify-items: left;
  gap: 1rem;
}

.Info > * {
  flex-basis: 99%;
}

.Link {
  margin-top: 1rem;
  display: inline-block;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

.Number {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-transform: uppercase;
}

.Title {
  font-size: 36px;
  color: $base-pink;
}

@media (min-width: $min-mobile-width) {
  .Info > * {
    flex-basis: 30%;
  }
}
