@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";

$mobile-page-width: 85%;
$desktop-page-width: 90%;
$max-page-width: 1280px;

.Wrapper {
  width: $mobile-page-width;
  max-width: $max-page-width;
  margin: auto;

  display: flex;
  flex-direction: column;
}

@media (min-width: $min-mobile-width) {
  .Wrapper {
    width: $desktop-page-width;
  }
}
