@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";
@import "/src/pages/Page.module.scss";

.Footer {
  background-color: $base-blue;
  margin-top: 1rem;
}

.Container {
  width: $mobile-page-width;
  margin: auto;
  padding-top: 2rem;
  max-width: $max-page-width;
  padding-bottom: 2rem;
}

.Line {
  color: $base-white;
  border: 1px solid;
  margin-top: 1rem;
}

.DisappearingLine {
  color: $base-white;
  border: 1px solid;
  margin-top: 1rem;
}

@media (min-width: $min-thin-desktop-width) {
  .Container {
    width: $desktop-page-width;
  }

  .Logo {
    display: none;
  }

  .DisappearingLine {
    display: none;
  }
}
