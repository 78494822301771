@import "/src/app/styles/resizeValues.scss";

.Container {
  ul {
    list-style: none;
  }

  li {
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
  }
}

@media (min-width: $min-mobile-width) {
  .Container {
    position: sticky;
    top: 22.5rem;
  }
}
