@import "/src/app/styles/index.scss";

.header {
  cursor: pointer;
  border: 0;
  font-family: "Montserrat";
  font-weight: 550;
  font-size: 1.2rem;
  background-color: $base-blue;
  color: $base-white;
  width: 100%;

  margin: 0;
  padding: 0;

  &:hover {
    background-color: $base-blue;
    text-decoration-line: none;
    color: $base-pink;
  }
}

.menu {
  z-index: 1001;
}

.menu ul {
  font-family: "Montserrat";
  box-sizing: border-box;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: $base-white;
  color: $base-blue;
  box-shadow: 0px 4px 30px $black-border;
  padding: 1rem;
  z-index: 1;
}

.menu a {
  font-weight: 500;
  color: $base-blue;
}

.menu li {
  list-style-type: none;
}
