@import "/src/app/styles/resizeValues.scss";

.Form {
  margin-top: 4%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
}

.ReviewImage {
  margin: auto;
}

@media (min-width: $min-mobile-width) {
  .Form {
    padding: 3rem;
  }
}
