@import "/src/pages/Page.module.scss";

.Wrapper {
  row-gap: 1rem;
}

.centeredContent {
  padding-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
