#indent {
  margin-bottom: 5px;
}

.Form {
  display: grid;
  grid-template-columns: 2fr 10fr;
  gap: 1rem;
}

.Label {
  align-self: center;
  justify-self: right;
  font-weight: bold;
}

.TinyLabel {
  align-self: top;
  padding-top: 10%;
  justify-self: right;
  font-weight: bold;
}

.InputHolder {
  flex-grow: 1;

  input,
  textarea {
    padding: 1%;
    width: 100%;
    font-size: medium;
  }
}

// .InputHolder >   img {
//   width: 200px
// }
