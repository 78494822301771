@import "/src/app/styles/index.scss";

.Container {
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
}

.Link {
  color: $base-blue;
  font-weight: 550;
}
