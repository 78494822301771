@import "/src/app/styles/index.scss";

.List {
  margin-left: 0.25rem;
  list-style-type: none;
  counter-reset: my-counter;

  li {
    &::before {
      color: $base-pink;
      font-family: Montserrat;
      font-weight: bold;

      counter-increment: my-counter;
      content: counter(my-counter);

      margin-left: -1.5em;
      position: absolute;
    }

    margin-bottom: 0.6rem;
  }
}

.ListText {
  font-size: 18px;
  color: $base-blue;
}
