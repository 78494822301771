@import "/src/app/styles/resizeValues.scss";
@import "/src/pages/Page.module.scss";

.Layout {
  display: grid;
  gap: 1rem;
}

.Text {
  grid-area: "text";
}

.Image {
  margin-top: 3rem;
  grid-area: "image";
}

.InstagramLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

.InstagramLink a {
  text-decoration: none;
}

@media (min-width: $min-mobile-width) {
  .Layout {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "text" "image";
    gap: 3rem;
  }
}
