@import "/src/pages/Page.module.scss";

.TitleHolder {
  max-width: 45rem;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-inline: auto;
}

.Title {
  text-align: center;
}
