@import "/src/app/styles/index.scss";

.DateText {
  text-align: left;
  text-decoration: none;
  color: #a09ea1;
}

.DateIcon {
  margin-right: 0.25%;
}
