@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";

.NavBar {
  background-color: $base-blue;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);

  box-sizing: border-box;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1000;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  padding-inline: 1rem;
  padding-block: 1em;
}

.DropDown {
  padding-inline: 0.75rem;
  width: fit-content;
  justify-content: center;
  align-content: center;
}

.DropDownActive {
  padding-inline: 0.75rem;
  width: fit-content;
  justify-content: center;
  align-content: center;
  background-color: $base-pink;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  border-radius: 2em;
}

.DropDownButtonActive {
  background-color: $base-pink;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  border-radius: 1em;

  &:hover {
    background-color: $base-pink;
    text-decoration-line: none;
    color: $base-blue;
  }
}

.Logo {
  display: none;
}

.Link {
  color: $base-white;
  text-decoration: none;
  text-transform: none;
  font-size: 1.2rem;
  font-weight: 550;
  padding: 0.5rem;

  &[aria-current="page"] {
    &:hover {
      color: $base-blue;
    }

    background-color: $base-pink;

    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    border-radius: 1em;
  }
}

.Button {
  background: $base-blue;
  border: 0;
  text-decoration: none;
  max-width: 200px;
  filter: none;
}

.Navigation {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DesktopNavigation {
  display: none;
}

.MobileNavigation {
  display: block;
}

.MobileDropDown {
  display: flex;
  flex-direction: column;
}

@media (min-width: $min-thin-desktop-width) {
  .NavBar {
    align-items: center;
    padding-block: 0.5rem;
    padding-inline: 2rem;
    gap: 0rem;
  }

  .Navigation {
    flex-direction: row;
    margin-top: 0rem;
    gap: 0.75rem;
  }

  .MobileNavigation {
    display: none;
  }

  .MobileDropDown {
    display: none;
  }

  .DesktopNavigation {
    display: contents;
  }

  .Logo {
    display: block;
  }
}
