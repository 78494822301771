@import "/src/app/styles/index.scss";

.Note {
  animation-duration: 1s;
  padding-inline: 2rem;
  padding-block: 1rem;
}

.NoteBackground {
  background: $background;
  border-radius: $border-radius;
  box-shadow: $box-shadow $box-shadow $box-shadow rgba(0, 0, 0, 0.2);

  animation-name: show;
  animation-duration: 1s;
}
