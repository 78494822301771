@import "/src/pages/Page.module.scss";
@import "/src/app/styles/index.scss";

.Title {
  margin-top: 5%;
  line-height: 0.9em;
}

.Text {
  margin-top: 2rem;
}

.Background {
  background: $background;
  border-radius: 1rem;
}

.Image {
  position: relative;
  top: -1rem;
}
