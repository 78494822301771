@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";

.Li {
  padding-left: 10px;
  padding-right: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin: 0 0 0.5rem 0;
  color: $base-pink;
}

.Table {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none;
  counter-reset: my-counter;
  //word-break: break-all;

  li {
    &::before {
      color: #e36171;
      font-family: Montserrat;
      font-style: normal;
      font-size: 18px;
      content: counter(my-counter);
      counter-increment: my-counter;
      position: absolute;
      margin-left: -1.5em;
      font-weight: bold;
      line-height: 22px;
    }

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: $base-blue;
    display: block;
    margin: 0 0 0.5rem -0.5em;
    position: -webkit-sticky;
  }
}

.ThemeLinks {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: $base-blue;
  margin: 0 0 0.5rem 0;
  display: block;
  text-decoration: underline;
}

.MainText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

@media (max-width: $min-mobile-width) {
  .Table {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}
