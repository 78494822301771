@import "/src/app/styles/index.scss";

.RadioButton {
  color: $base-blue !important;
}

.SimpleAnswer {
  width: auto;
}

.antInput {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.antInputUnderlined {
  border-bottom-color: #111111;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;

  box-shadow: none;

  &:hover {
    border-bottom-color: #111111;
  }
}
