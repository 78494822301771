@import "/src/app/styles/index.scss";

.Button {
  cursor: pointer;

  width: fit-content;
  padding: 0.3rem;

  box-sizing: border-box;

  text-transform: none;
  text-align: center;
  text-decoration-line: underline;

  font-size: 20px;
  font-family: Montserrat;
  font-weight: bold;

  border: solid $base-pink 0.2px;
  border-radius: 10px;

  background: $base-pink;

  color: $base-white;
  filter: drop-shadow(0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.25));

  &:hover {
    color: $base-blue;
    text-decoration-line: none;
  }
}
