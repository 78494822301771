.WithVerticalText {
  width: 100%;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.AlignedRight {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
  vertical-align: middle;
}

.Title {
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: normal;
}
