.Title {
  font-size: 25px;
  line-height: 23px;
  margin-bottom: 40px;
  text-align: center;
}

.AuthInputsRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.ButtonsHolder {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.LoginContainer {
  min-height: calc(100vh - 390px);
}

.ErrorMsg {
  color: red;
}

.lala {
  width: 100%;
}
