@import "/src/pages/Page.module.scss";

.Title {
  text-align: center;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
