@import "/src/pages/Page.module.scss";
@import "/src/app/styles/index.scss";

.RedirectLink {
  color: $base-white;
  font-size: 30px;
}

.Wrapper {
  row-gap: 2rem;
}

@media (min-width: $min-mobile-width) {
  .Wrapper {
    row-gap: 5rem;
  }
}
