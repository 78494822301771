@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
@import "./resizeValues.scss";

$base-white: #ffffff;
$base-pink: #eb5e70;
$base-blue: #183345;
$black-border: rgba(24, 51, 69, 0.3);
$theme-header-color: #feb47b;

$image-background: #d8d8d8;
$image-background-hover: #dddddd;
$background: #e4e4e4;

$border-radius: 0.5rem;
$box-shadow: 0.5rem;

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// body,
// h1,
// h2,
// h3,
// h4,
// h5,
// p,
// ul,
// ol,
// li,
// figure,
// figcaption,
// blockquote,
// dl,
// dd {
//   padding: 0;
//   margin: 0;
// }

Link,
a {
  color: $base-blue;
  font-size: 18px;
  font-family: Montserrat;

  &:hover {
    color: $base-pink;
    text-decoration-line: none;
  }
}

.centeredArrowDown {
  padding-top: 50px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.themeNames {
  padding-top: 50px;
  min-height: 200px;
  word-break: break-all;
}

hr {
  width: 100%;
  border: 2px solid #f0f0f0;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

p {
  font-size: 16px;
}

li {
  font-size: 16px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 23px;
}

h3 {
  font-size: 20px;
}

p,
h1,
h2,
h3,
h4,
ol,
ul,
li {
  color: $base-blue;
  font-family: Montserrat;
}

@media (min-width: $min-mobile-width) {
  h1 {
    font-size: 58px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 29px;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
