@import "/src/app/styles/index.scss";

.List {
  margin-left: 2%;
  list-style-type: square;
  padding-left: 1%;
  color: $base-pink;
}

.ListSimple {
  margin-left: 2%;
  padding-left: 1%;
  list-style-type: disc;
  color: $base-blue;
}

.ListText {
  text-align: left;
  text-decoration: none;
  font-size: 18px;
  color: $base-blue;
}
