@import "/src/app/styles/index.scss";

h3 {
  margin-bottom: 0;
}

h4 {
  margin-top: 10px;
}

.Form {
  display: flex;
  flex-direction: column;

  animation-name: show;
  animation-duration: 1s;

  @keyframes show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
