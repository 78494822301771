@import "/src/pages/Page.module.scss";

.Title {
  text-align: center;
}

.ErrorMsg {
  color: red;
  font-size: 13px;
}
