@import "/src/app/styles/index.scss";

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  color: $base-blue;
  background-color: $theme-header-color;
}

.Title {
  text-align: center;
  font-weight: bolder;
  font-size: 36px;
  overflow-wrap: break-word;
}
