@import "/src/app/styles/index.scss";

.Card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: $base-blue;
}

.Background {
  background: $background;
  border-radius: $border-radius;
}

.Link {
  text-decoration: none;
  color: $base-blue;
  font-size: 2rem;
  font-weight: bold;

  &:hover {
    color: $base-pink;
  }
}
