@import "/src/pages/Page.module.scss";
@import "/src/app/styles/resizeValues.scss";

.Wrapper {
  row-gap: 2rem;
}

@media (min-width: $min-mobile-width) {
  .Wrapper {
    row-gap: 5rem;
  }
}
