@import "/src/app/styles/index.scss";
@import "/src/app/styles/resizeValues.scss";
@import "/src/pages/Page.module.scss";

.Header {
  display: flex;
  flex-direction: column;

  padding-top: 0.5rem;
  background-color: $base-blue;
}

.Title {
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;

  color: $base-white;
  text-align: left;
}

.Text {
  text-align: left;
  color: $base-white;
}

.RocketImage {
  width: 100%;
  margin-bottom: -5px;
  height: auto;
}

@media (min-width: $min-mobile-width) {
  .Header {
    padding-top: 4rem;
  }

  .Title {
    text-align: center;
    margin-inline: auto;
  }

  .Text {
    text-align: center;

    margin-top: 1rem;
    margin-inline: auto;
  }
}
